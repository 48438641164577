import React from 'react';
import TopNavLogo from 'components/nav/TopNav/TopNavLogo';

import {
  Container,
  ContentWrapper,
  StyledLink,
  StyledPhoneIcon,
  StyledTruckDeliveryIcon,
  StyledCartContainer,
} from './PublicRegistryNavV2.styles';

const PublicRegistryNavV2 = (): JSX.Element => {
  return (
    <Container>
      <TopNavLogo />
      <ContentWrapper>
        <StyledLink
          href="/faq/category/115000284811-for-gift-givers-wedding-guests"
          target="_blank"
          rel="noopener noreferrer"
        >
          About Zola
        </StyledLink>
        <StyledLink href="tel:14086579652" target="_blank" rel="noopener noreferrer" subtle>
          <StyledPhoneIcon title="1-408-657-ZOLA" width={19} height={19} /> 1-408-657-ZOLA
        </StyledLink>
        <StyledLink
          href="/faq/115002839971-does-zola-offer-free-shipping-"
          target="_blank"
          rel="noopener noreferrer"
          subtle
          noTextTransform
        >
          <StyledTruckDeliveryIcon title="Free shipping, every day" width={19} height={19} /> Free
          shipping, every day
        </StyledLink>
        <StyledCartContainer />
      </ContentWrapper>
    </Container>
  );
};

export default PublicRegistryNavV2;
